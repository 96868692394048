@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Cabin:600,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
:root {
  --link-color: #fdbd28;
  --light-gray: #eeeeee;
  --black: #444;
}

body {
  margin: 0;
  padding: 0.3rem;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-header {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
  margin-bottom: 2rem;
  /* padding: 1rem; */
}

.app-header img {
  height: 40px;
}

.app-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.app-body table {
  margin: 1rem 0px 10px;
  min-width: 100%;
  text-align: left;
}

.footer {
  padding: 1rem;
  height: 50px;
  background: var(--black);
  padding: 1rem;
  color: var(--light-gray);
}

.App-link {
  color: #61dafb;
}

.pull-left {
  display: flex;
  align-items: center;
}

.btn {
  padding: 1rem;
  background-color: var(--link-color);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: var(--black);
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.app-header a{color:var(--link-color);text-decoration:none;font-weight:600}.app-header .up{display:flex;flex-direction:row;padding:1rem;justify-content:space-between;align-items:center}

.nav-scroller{position:relative;z-index:2;height:2.75rem;overflow:hidden;margin-bottom:1.5rem !important;box-shadow:0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;background-color:#ffc107 !important}.nav-scroller .nav{display:flex;flex-wrap:nowrap;padding-bottom:1rem;margin-top:-1px;overflow-x:auto;color:rgba(255,255,255,0.75);text-align:center;white-space:nowrap;-webkit-overflow-scrolling:touch}.nav-scroller .nav.nav-underline{justify-content:flex-start;padding-bottom:0px;padding-top:.35em}.nav-scroller .nav.nav-underline .nav-link{padding-top:.75rem;padding-bottom:0px;font-size:.875rem;color:#6c757d;padding-top:0.5rem !important;margin-top:0.25rem !important;margin-left:0.25rem !important;margin-right:0.25rem !important;transition:all 0.9s}.nav-scroller .nav.nav-underline .nav-link.active{font-weight:500;color:#343a40}.nav-scroller .nav.nav-underline .nav-link:hover{color:#007bff}

.wrapper{width:100%;height:100%;display:flex;justify-content:center;max-width:1100px}.wrapper .card-verification .card-verification-form{width:100%;padding-top:1rem}.wrapper .card-verification .form-group{display:flex;flex-direction:column;margin:1rem 0rem;align-items:normal;text-align:left;text-align:initial}.wrapper .card-verification .form-group label{margin-left:1rem;font-weight:600}.wrapper .card-verification .form-group .label{margin:0 0.5rem 0 1rem;line-height:3.3rem}.wrapper .card-verification .form-group .input-field{flex-grow:1;margin:1rem 0 0;padding:1rem;border:1px solid var(--light-gray);border-radius:1rem;font-size:14px;font-weight:600}.wrapper .card-verification .form-group .input-field.card-holder{display:flex;padding:0;text-align:center}.wrapper .card-verification .form-group .input-field.card-holder label{padding:1rem}.wrapper .card-verification .form-group button{padding:1rem;border:1px solid var(--light-gray);border-radius:10px;width:200px}.wrapper .card-verification .image-holder{background-size:contain;background-repeat:no-repeat;width:50vw;max-width:680px;min-height:50vh}.wrapper .card-verification .btn{background:var(--link-color);font-weight:600}.wrapper div.input{border-left:1px solid var(--light-gray);padding:1rem;flex-grow:1}.wrapper [contenteditable][placeholder]:empty:before{content:attr(placeholder);color:gray;background-color:transparent}

.errorWrapper{max-width:400px;line-height:1.5rem}.btn-error{margin-top:2rem}

.customer-details table tbody td{width:150px;padding:10px 20px 10px 20px;font-size:13px;text-transform:uppercase}.customer-details table tbody td:first-of-type{background-color:#feebbe;border:1px solid #feebbe}

