
.customer-details {
  table tbody {
    td{
      width: 150px;
      padding: 10px 20px 10px 20px;
      font-size: 13px;
      text-transform: uppercase;

      &:first-of-type{
        background-color: #feebbe;
        border: 1px solid #feebbe;
      }
    }
  }
}