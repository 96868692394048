.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  max-width: 1100px;

  .card-verification {
    // display: flex;
    // justify-content: center;
    // padding: 0 1rem 3rem;
    // margin: 3rem;


    .card-verification-form {
      // min-width: 200px;
      // max-width: 30vw;
      width: 100%;
      // display: flex;
      // flex-direction: column;
      padding-top: 1rem;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      // min-width: 300px;
      // width: calc(100% - 3rem);
      margin: 1rem 0rem;
      align-items: normal;
      text-align: initial;

      label {
        margin-left: 1rem;
        font-weight: 600;
      }

      .label {
        margin: 0 0.5rem 0 1rem;
        line-height: 3.3rem;
      }
    }

    .form-group .input-field {
      flex-grow: 1;
      margin: 1rem 0 0;
      padding: 1rem;
      border: 1px solid var(--light-gray);
      border-radius: 1rem;
      font-size: 14px;
      font-weight: 600;

      &.card-holder {
        display: flex;
        padding: 0;
        text-align: center;

        label {
          padding: 1rem;
        }
      }
    }

    .form-group button {
      padding: 1rem;
      border: 1px solid var(--light-gray);
      border-radius: 10px;
      width: 200px;
    }

    .image-holder {
      background-size: contain;
      background-repeat: no-repeat;
      width: 50vw;
      max-width: 680px;
      min-height: 50vh;
    }

    .btn {
      background: var(--link-color);
      font-weight: 600;
    }
  }

  div.input {
    border-left: 1px solid var(--light-gray);
    padding: 1rem;
    flex-grow: 1;
  }

  [contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    color: gray;
    background-color: transparent;
  }
}

