@import url('https://fonts.googleapis.com/css?family=Cabin:600,700&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow: hidden;
  margin-bottom: 1.5rem!important;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  background-color: #ffc107!important;

  .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    color: rgba(255, 255, 255, .75);
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

      &.nav-underline {
        justify-content: flex-start;
        padding-bottom: 0px;
        padding-top: .35em;
        .nav-link {
          padding-top: .75rem;
          padding-bottom: 0px;
          font-size: .875rem;
          color: #6c757d;
          // padding-bottom: 1rem!important;
          padding-top: .5rem!important;
          margin-top: .25rem!important;
          margin-left: .25rem!important;
          margin-right: .25rem!important;
          transition: all 0.9s;

        &.active {
          font-weight: 500;
          color: #343a40;
        }

        &:hover {
          color: #007bff;
        }
      }
    }
  }
}
