.app-header {
  a {
    color: var(--link-color);
    text-decoration: none;
    font-weight: 600;
  }
  .up{
    display: flex;
    flex-direction: row;
    padding: 1rem;;
    justify-content: space-between;
    align-items: center;
  }
}